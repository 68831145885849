import One from '../images/artwork/Temenos/MonicaDixon4.jpg'
import Two from '../images/artwork/Temenos/mask_lo_012.jpg'
import Three from '../images/artwork/Temenos/mask_lo_020.jpg'
import Four from '../images/artwork/Temenos/mask_lo_028.jpg'
import Five from '../images/artwork/Temenos/mask_lo_056.jpg'
import Six from '../images/artwork/Temenos/mask_lo_061.jpg'
import Seven from '../images/artwork/Temenos/mask_lo_067.jpg'
import Eight from '../images/artwork/Temenos/mask_lo_088.jpg'
import Nine from '../images/artwork/Temenos/mask_lo_081.jpg'
import Ten from '../images/artwork/Temenos/mask_lo_098.jpg'
import Eleven from '../images/artwork/Temenos/mask_lo_106.jpg'
import Twelve from '../images/artwork/Temenos/mask_lo_108.jpg'
import Thirteen from '../images/artwork/Temenos/mask_lo_110.jpg'
import Fourteen from '../images/artwork/Temenos/mask_lo_114.jpg'
import Fifteen from '../images/artwork/Temenos/mask_lo_122.jpg'
import Sixteen from '../images/artwork/Temenos/mask_lo_125.jpg'

export const photos = [

      {
        src: One,
        width: 2.25,
        height: 1.5,
        // title: "Slake, 2017"
      },
      {
        src: Two,
        width: 4.5,
        height: 3,
        // title: "I'm Working on my Posture, 2017"
      },
      {
        src: Three,
        width: 4,
        height: 3,
        // title: "Wing, 2017"
      },
      {
        src: Four,
        width: 4.5,
        height: 3,
        // title: "Wing, 2017"
      },      {
        src: Five,
        width: 3,
        height: 4,
        // title: "Wing, 2017"
      },      {
        src: Six,
        width: 4.5,
        height: 3,
        // title: "Wing, 2017"
      },      {
        src: Seven,
        width: 4,
        height: 4,
        // title: "Wing, 2017"
      },      {
        src: Eight,
        width: 3.75,
        height: 4,
        // title: "Wing, 2017"
      },      {
        src: Nine,
        width: 2.5,
        height: 4,
        // title: "Wing, 2017"
      },      {
        src: Three,
        width: 4,
        height: 3,
        // title: "Wing, 2017"
      },
      {
        src: Ten,
        width: 4,
        height: 4,
        // title: "Wing, 2017"
      },
      {
        src: Eleven,
        width: 4,
        height: 4,
        // title: "Wing, 2017"
      },
      {
        src: Twelve,
        width: 3,
        height: 4,
        // title: "Wing, 2017"
      },
      {
        src: Thirteen,
        width: 4.5,
        height: 3,
        // title: "Wing, 2017"
      },
      {
        src: Fourteen,
        width: 4.5,
        height: 3,
        // title: "Wing, 2017"
      },
      {
        src: Fifteen,
        width: 3,
        height: 4.5,
        // title: "Wing, 2017"
      },
      {
        src: Sixteen,
        width: 4,
        height: 3,
        // title: "Wing, 2017"
      },
  ];